<header class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4">
  <div style="font-size: large; line-height: 1; margin-top: 6px;">
    <p style="float: left"><img alt="Cerco verde" src="assets/imgs/logo_white.png" height="50px"></p>
    <p style="margin-top: 6px;">&nbsp;&nbsp;cerco <span style="font-weight: bold">&nbsp;&nbsp;verde</span></p>
  </div>
  <nav class="my-2 my-md-0 me-md-3 ml-auto text-center">
    <a class="p-2" href="#acciones_verdes">{{'navbar.acciones_verdes' | translate}}</a>
    <a class="p-2" href="#detras_del_cerco">{{'navbar.detras_del_cerco' | translate}}</a>
    <a class="p-2" href="#experiencias">{{'navbar.experiencias' | translate}}</a>
    <a class="p-2" href="#contacto">{{'navbar.contacto' | translate}}</a>
  </nav>

  <span class="form-inline">
    <select class="form-control bg-transparent border-0 text-white" style="cursor:pointer;" #selectedLang
      (change)="switchLang(selectedLang.value)">
      <option style="color:black;" *ngFor="let language of translate.getLangs()" [value]="language"
        [selected]="language === translate.currentLang">
        {{ language }}
      </option>
    </select>
  </span>
</header>

<main class="container-fluid">

  <section id="pon_tu_cerco" class="row m-0">
    <div class="col-sm-12 text-center">
      <img alt="Cerco verde" src="assets/imgs/logo_color.png" height="150">
      <h1 class="display-4">
        <span style="font-weight: bold;margin-bottom: 1rem;display: block;">
          {{'pon_tu_cerco.t1' | translate}}
        </span>
        <p style="font-weight: 400;font-size: 1.3rem;">
          {{'pon_tu_cerco.t2' | translate}}
        </p>
      </h1>
      <a class="btn btn-outline-light m-2" style="border-radius: 40px;display: none;" href="https://mi.cercoverde.com">
        {{'pon_tu_cerco.publica_gratis' | translate}}
      </a>
      <a class="btn btn-outline-light m-2" style="border-radius: 40px;display: none;"
        href="https://mi.cercoverde.com/#/register">
        {{'pon_tu_cerco.ingresa_o_registrate_gratis' | translate}}
      </a>
    </div>
  </section>

  <section id="directa_y_eficaz" class="row m-0">
    <div class="col-sm-8 col-2"></div>
    <div class="col-sm-4 col-10">
      <div
        style="font-size: large; line-height: 1; margin-top: 6px;display: flex;align-items: center;justify-content: flex-start;">
        <img alt="Cerco verde" src="assets/imgs/logo_white.png" height="70">
        <p style="margin-left: 1rem;display: block;margin-bottom: 0;">
          {{'directa_y_eficaz.t1' | translate}}
        </p>
      </div>
    </div>
  </section>

  <section id="para_preservar" class="row m-0"
    style="background-blend-mode: multiply; background-color: rgba(0, 0, 0, 0.45);">
    <div class="col-sm-3 col-1"></div>
    <div class="col-sm-6 col-10 text-center">
      <div class="row m-0">
        <div class="" style="display:flex;align-items: center;justify-content: center;gap:4rem;flex-flow: wrap row;">
          <img alt="Cerco verde" src="assets/imgs/logo_white_larger.png" style="margin-top: 5px;" height="180">

          <div class="col-sm-10 backg-ash-85 p-4" style="font-size: large; line-height: normal; margin-top: 12px;">
            {{'para_preservar.tu_propio_cerco_para_preservar.t1' | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-1"></div>
  </section>

  <section id="alcance_y_ubicacion" class="row m-0">
    <div class="col-sm-12 text-left">
      <div style="font-size: x-large; line-height: 1; margin-top: 6px;">
        <div class="ml-sm-5"
          style="float: left; margin-top: -5px;display: flex;align-items: center;justify-content: center;gap: 1rem;">
          <img alt="Cerco verde" src="assets/imgs/logo_white.png" height="70px">
          <p style="margin: 0;font-size:large;">{{'alcance_y_ubicacion.tu_eliges_alcance_y_ubicacion.t1' | translate}}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="acciones_verdes" class="row m-0">
    <div class="col-sm-9 p-0" style="background-blend-mode: multiply; background-color: rgba(0, 0, 0, 0.45);">
      <div class="row m-0">
        <div id="acciones_verdes_main_title" class="col-sm-12">
          <h2 style="font-size: xx-large;">
            {{'acciones_verdes.acciones_verdes.acciones' | translate}}
            <span style="font-weight: bold;">
              {{'acciones_verdes.acciones_verdes.verdes' | translate}}
            </span>
          </h2>
        </div>
        <div id="acciones_verdes_main_body" class="col-sm-12 m-5">
          <div class="row m-0">
            <div class="col-sm-10 pr-0">
              <div class="row ml-sm-4">
                <div class="row row-cols-1 row-cols-md-3 ml-sm-4">
                  <div class="col modal-link" (click)="open(preserva_terrenos_verdes)">
                    <div class="card mb-4 p-sm-2">
                      <div id="acciones_verdes_main_body_1" class="card-body p-0">
                      </div>
                      <div class="card-footer" style="min-height: 25vh;">
                        <img alt="Acción" src="assets/imgs/accion75.png">
                        <br />
                        <span class="color-green" style="font-weight: bold;">
                          {{'acciones_verdes.preserva_terrenos_verdes.preserva' | translate}}
                        </span>
                        <br />
                        {{'acciones_verdes.preserva_terrenos_verdes.terrenos' | translate}}
                        {{'acciones_verdes.preserva_terrenos_verdes.verdes' | translate}}

                      </div>
                    </div>
                  </div>
                  <div class="col modal-link" (click)="open(preserva_comunidades_verdes)">
                    <div class="card mb-4 p-sm-2">
                      <div id="acciones_verdes_main_body_2" class="card-body p-0">
                      </div>
                      <div class="card-footer" style="min-height: 25vh;">
                        <img alt="Acción" src="assets/imgs/accion75.png">
                        <br />
                        <span class="color-green"
                          style="font-weight: bold;">{{'acciones_verdes.preserva_comunidades_verdes.preserva' |
                          translate}}</span>
                        <br />

                        {{'acciones_verdes.preserva_comunidades_verdes.comunidades' | translate}}
                        {{'acciones_verdes.preserva_comunidades_verdes.verdes' | translate}}

                      </div>
                    </div>
                  </div>
                  <div class="col modal-link" (click)="open(puntos_negocios_verdes)">
                    <div class="card mb-4 p-sm-2">
                      <div id="acciones_verdes_main_body_3" class="card-body p-0">
                      </div>
                      <div class="card-footer" style="min-height: 25vh;">
                        <img alt="Acción" src="assets/imgs/accion75.png">
                        <br />
                        <span class="color-green"
                          style="font-weight: bold;">{{'acciones_verdes.puntos_negocios_verdes.puntos' |
                          translate}}</span>
                        <br />

                        {{'acciones_verdes.puntos_negocios_verdes.negocios' | translate}}
                        {{'acciones_verdes.puntos_negocios_verdes.verdes' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 p-0 text-center modal-link" (click)="open(conviertete_en_guardian_verde)">
      <div class="row m-0">
        <div id="acciones_verdes_side_body" class="col-sm-12">
        </div>
        <div id="acciones_verdes_side_title" class="col-sm-12">
          <div class="row m-0">
            <div class="col-sm-12" style="margin-top: -60px;">
              <img alt="Cerco verde" src="assets/imgs/logo_color.png" height="100">
            </div>
            <div class="col-sm-12">
              <div style="font-size: x-large;">
                {{'acciones_verdes.conviertete_en_guardian_verde.conviertete_en' | translate}}
                <br />
                <span style="font-weight: bold;">
                  {{'acciones_verdes.conviertete_en_guardian_verde.guardian_verde' | translate}}
                  <br /> &nbsp;
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="stop_deforestacion" class="row m-0">
    <div class="col-sm-12 text-center">
      <div id="stop_deforestacion_title" class="row">
        <div class="col-sm-12">
          <h2 style="font-size: x-large;">
            {{'stop_deforestacion.con_cercoverde_ponemos_stop.con' | translate}}
            <span style="font-weight: bold; font-size: larger;">
              {{'stop_deforestacion.con_cercoverde_ponemos_stop.cercoverde' | translate}}
            </span>
            {{'stop_deforestacion.con_cercoverde_ponemos_stop.ponemos' | translate}}
            <br />
            <span style="font-weight: bold; font-size: larger;">
              {{'stop_deforestacion.con_cercoverde_ponemos_stop.stop' | translate}}
            </span>
          </h2>
        </div>
      </div>
      <div id="stop_deforestacion_body" class="row p-4">
        <div class="col-sm-12">
          <div class="row row-cols-1 row-cols-md-4 mb-3 text-center">
            <div class="col">
              <div class="card mb-4">
                <div id="stop_deforestacion_body_1" class="card-body">
                  <img src="assets/imgs/stop_deforestacion_1.png">
                </div>
                <div class="card-footer backg-white">
                  {{'stop_deforestacion.perdida_de_biodiversidad' | translate}}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4">
                <div id="stop_deforestacion_body_2" class="card-body">
                  <img src="assets/imgs/stop_deforestacion_2.png">
                </div>
                <div class="card-footer backg-white">
                  {{'stop_deforestacion.desertificacion' | translate}}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4">
                <div id="stop_deforestacion_body_3" class="card-body">
                  <img src="assets/imgs/stop_deforestacion_3.png">
                </div>
                <div class="card-footer backg-white">
                  {{'stop_deforestacion.sequia' | translate}}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4">
                <div id="stop_deforestacion_body_4" class="card-body">
                  <img src="assets/imgs/stop_deforestacion_4.png">
                </div>
                <div class="card-footer backg-white">
                  {{'stop_deforestacion.cambio_climatico' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="some_pictures" class="row m-0">
    <div class="col-sm-4">
      <div class="row">
        <div id="some_pictures_picture_1" class="col-sm-12">
        </div>
      </div>
      <div class="row">
        <div id="some_pictures_picture_2" class="col-sm-12">
        </div>
      </div>
    </div>
    <div id="some_pictures_picture_3" class="col-sm-8">
      <img class="m-4" alt="Cerco verde" src="assets/imgs/logo_white.png">
    </div>
  </section>

  <section id="detras_del_cerco" class="row m-0">
    <div class="col-sm-12 text-center">
      <div id="detras_del_cerco_title" class="row pt-4 pb-4 pt-sm-0 pb-sm-0">
        <div class="col-sm-12">
          <h2 style="font-size: x-large;font-weight: 400;">
            <span class="font-weight-bold">{{'detras_del_cerco.detras_del_cerco.detras_del' | translate}}</span>
            <br />
            {{'detras_del_cerco.detras_del_cerco.cerco' | translate}}
          </h2>
        </div>
      </div>
      <div id="detras_del_cerco_body" class="row p-0">
        <div class="col-sm-6 text-left backg-ash-gradient pl-2 pt-2 pb-2 p-sm-4">
          <div style="line-height: 1;">
            <div class="ml-sm-5" style="float: left;">
              <img alt="Toma acción" src="assets/imgs/accion_white.png" height="40">
              &nbsp;&nbsp;
            </div>
            <div>
              {{'detras_del_cerco.territorios_indigenas_son_la_biodiversidad.territorios_indigenas' | translate}}
              <br />
              {{'detras_del_cerco.territorios_indigenas_son_la_biodiversidad.son_la' | translate}}
              <span class="color-green" style="font-weight: bold; font-size: larger;">
                {{'detras_del_cerco.territorios_indigenas_son_la_biodiversidad.biodiversidad' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="guardianes_naturaleza" class="row m-0 d-none">
    <div class="col-sm-12 text-center">
      <div id="guardianes_naturaleza_title" class="row pt-4 pt-md-0">
        <div class="col-sm-12">
          <h2 style="font-size: x-large;">
            {{'guardianes_naturaleza.somos_los_guardiantes_te_invitamos_a_serlo.somos_los' | translate}}
            <span style="font-weight: bold; font-size: larger;">
              {{'guardianes_naturaleza.somos_los_guardiantes_te_invitamos_a_serlo.guardianes' | translate}},
            </span>
            <br />
            {{'guardianes_naturaleza.somos_los_guardiantes_te_invitamos_a_serlo.te_invitamos' | translate}}
            <span class="color-green" style="font-weight: bold; font-size: larger;">
              {{'guardianes_naturaleza.somos_los_guardiantes_te_invitamos_a_serlo.a_serlo' | translate}}
            </span>
          </h2>
        </div>
      </div>
      <div id="guardianes_naturaleza_body" class="row p-0 pb-4">
        <div class="col-sm-12 text-left p-2 p-sm-4">
          <div class="col-sm-12">
            <div class="row text-center" style="font-size: small;">
              <div class="col-sm-2 mb-3 mb-sm-0"></div>
              <div class="col-sm-2 mb-3 mb-sm-0 color-green"><img src="assets/imgs/avatar_rounded.png"> <br /><br />
                ESTELLA SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0"><img src="assets/imgs/avatar_rounded_alt.png"> <br /><br /> ESTELLA
                SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0 color-green"><img src="assets/imgs/avatar_rounded.png"> <br /><br />
                ESTELLA SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0"><img src="assets/imgs/avatar_rounded_alt.png"> <br /><br /> ESTELLA
                SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0"></div>
            </div>
            <div class="row text-center mt-5" style="font-size: small;">
              <div class="col-sm-2 mb-3 mb-sm-0"></div>
              <div class="col-sm-2 mb-3 mb-sm-0"><img src="assets/imgs/avatar_rounded_alt.png"> <br /><br /> ESTELLA
                SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0 color-green"><img src="assets/imgs/avatar_rounded.png"> <br /><br />
                ESTELLA SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0"><img src="assets/imgs/avatar_rounded_alt.png"> <br /><br /> ESTELLA
                SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0 color-green"><img src="assets/imgs/avatar_rounded.png"> <br /><br />
                ESTELLA SINGURI <br /> {{'guardianes_naturaleza.people.lider_tacanas' | translate}} </div>
              <div class="col-sm-2 mb-3 mb-sm-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="detras_del_cerco_2" class="row m-0">
    <div class="col-sm-12 text-center">
      <div id="stop_deforestacion_title" class="row">
        <div class="col-sm-12">
          <h2 style="font-size: x-large;">
            <span style="font-weight: bold; font-size: larger;">
              {{'detras_del_cerco.t2' | translate}}
            </span>
          </h2>
        </div>
      </div>
      <div id="" class="row p-4 images-behind-the-fence"
        style="min-height: 75vh;position:relative;z-index: 1;display: flex;align-items: center;justify-content: center;flex-flow: nowrap row;padding:4rem;">
        <div
          style="position: absolute;inset:0 0 0 0;background-color:transparent;filter: brightness(40%);z-index: 2;background-image: url(assets/imgs/bg-behind-the-fence.jpg);background-size: cover;background-position: 50% 100%;">
        </div>
        <img src="assets/imgs/behindthefence/i1.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
        <img src="assets/imgs/behindthefence/i2.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
        <img src="assets/imgs/behindthefence/i3.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
        <img src="assets/imgs/behindthefence/i4.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
        <img src="assets/imgs/behindthefence/i5.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
        <img src="assets/imgs/behindthefence/i6.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
        <img src="assets/imgs/behindthefence/i7.png" alt="" class=""
          style="border-radius: 50%;width:8rem;height:8rem;z-index:3;" />
      </div>
    </div>

    <div class="row p-4 m-0 behind-the-fente-texts"
      style="background-color: #302f2f;width: 100%;display: flex;align-items: stretch;justify-content: center;">
      <div style="display: flex;flex-flow: nowrap row;align-items: center;
      justify-content: center;color:white;gap:.5rem;font-size:x-large;">
        <b>40</b>
        <span>{{'detras_del_cerco.tt1' | translate}}</span>
      </div>

      <div class="separator" style="width:1px;border-right: 2px dotted green;margin: 0 1rem;"></div>

      <div style="display: flex;flex-flow: nowrap row;align-items: center;
      justify-content: center;color:white;gap:.5rem;font-size:x-large;">
        <b>+ 1000</b>
        <span>{{'detras_del_cerco.tt2' | translate}}</span>
      </div>

      <div class="separator" style="width:1px;border-right: 2px dotted green;margin: 0 1rem;"></div>

      <div style="display: flex;flex-flow: nowrap row;align-items: center;
      justify-content: center;color:white;gap:.5rem;font-size:x-large;">
        <b>200</b>
        <span>{{'detras_del_cerco.tt3' | translate}}</span>
      </div>

      <div class="separator" style="width:1px;border-right: 2px dotted green;margin: 0 1rem;"></div>

      <div style="display: flex;flex-flow: nowrap row;align-items: center;
      justify-content: center;color:white;gap:.5rem;font-size:x-large;">
        <b>10M</b>
        <span>{{'detras_del_cerco.tt4' | translate}}</span>
      </div>
    </div>
  </section>

  <section id="videos" class="row pt-sm-5 pt-2 pb-sm-5 pb-1">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <div class="row">
            <div class="row row-cols-1 row-cols-md-3 m-2">
              <div class="col">
                <div class="card mb-4">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/qek9Kd3-QHg"
                      allowfullscreen></iframe>
                  </div>
                  <div class="card-body p-0">
                    <span style="font-weight: bold;">
                      {{'videos.video_1.titulo' | translate}}
                    </span> <br />
                    {{'videos.video_1.descripcion' | translate}}
                  </div>

                </div>
              </div>
              <div class="col">
                <div class="card mb-4">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/hhJsFKo6sMA"
                      allowfullscreen></iframe>
                  </div>
                  <div class="card-body p-0">
                    <span style="font-weight: bold;">
                      {{'videos.video_2.titulo' | translate}}
                    </span> <br />
                    {{'videos.video_2.descripcion' | translate}}
                  </div>

                </div>
              </div>
              <div class="col">
                <div class="card mb-4">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/2GpkiwvUgWw"
                      allowfullscreen></iframe>
                  </div>
                  <div class="card-body p-0">

                    <span style="font-weight: bold;">
                      {{'videos.video_3.titulo' | translate}}
                    </span> <br />
                    {{'videos.video_3.descripcion' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </section>

  <section id="experiencias" class="row m-0">
    <div class="col-sm-12 text-center">
      <div id="experiencias_title" class="row">
        <div class="col-sm-12">
          <h2 style="font-size: xx-large;">
            <span style="font-weight: bold; font-size: larger;">
              {{'experiencias.experiencias' | translate}}
            </span>
          </h2>
        </div>
      </div>
      <div id="experiencias_body" class="row">
        <div class="col-sm-12 text-center">
          <div class="row row-cols-1 row-cols-md-3 text-center m-1">
            <div class="col mb-1 mt-1 p-1">
              <div class="card">
                <div id="experiencias_body_1" class="card-body">
                </div>
                <div class="card-footer" style="background-color: #70ad41; color: white;">
                  <span style="font-weight: bold;">
                    {{'experiencias.testimonios.luciana_capra.nombre' | translate}}
                  </span> <br />
                  "{{'experiencias.testimonios.luciana_capra.testimonio' | translate}}"
                </div>
              </div>
            </div>
            <div class="col mb-1 mt-1 p-1">
              <div class="card">
                <div class="card-header" style="background-color: white; color: black;">
                  <span style="font-weight: bold;">
                    {{'experiencias.testimonios.mario_costas.nombre' | translate}}
                  </span> <br />
                  "{{'experiencias.testimonios.mario_costas.testimonio' | translate}}"
                </div>
                <div id="experiencias_body_2" class="card-body">
                </div>
              </div>
            </div>
            <div class="col mb-1 mt-1 p-1">
              <div class="card">
                <div id="experiencias_body_3" class="card-body">
                </div>
                <div class="card-footer" style="background-color: #b5a89a; color: white;">
                  <span style="font-weight: bold;">
                    {{'experiencias.testimonios.carla_jaimes.nombre' | translate}}
                  </span> <br />
                  "{{'experiencias.testimonios.carla_jaimes.testimonio' | translate}}"
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="detras_del_cerco_2" class="row m-0 p-0">
    <div class="col-sm-12 text-center p-0">
      <div id="stop_deforestacion_title" class="row">
        <div class="col-sm-12">
          <h2 style="font-size: xx-large;">
            <span style="font-weight: bold; font-size: larger;">
              {{'detras_del_cerco.tt5' | translate}}
            </span>
          </h2>
        </div>
      </div>
      <div id="" class="row p-4 m-0 reconocimientos-last" style="display:flex;flex-flow:wrap row;align-items: center;justify-content: center;gap:10vw; row-gap: 1rem;background-color: #F6F6F6;">
        <img src="assets/imgs/cercoverde-reconocimientos.png" alt="" style="border-radius: 1rem;max-width: 100%;">


        <div class="card-rec" style="display: flex;flex-flow: nowrap column;align-items: center;justify-content: center;margin-top: 3rem;">
          <img src="https://nuevaeconomia.com.bo/img/ph/team/CarolaCapra.png" alt="" style="width: 6rem;height: 6rem; border-radius: 50%;display: block;object-fit: cover;z-index: 10;">

          <div style="z-index:9;background-color: white;display: flex;flex-flow:nowrap column;align-items: center; justify-content: flex-end;border-radius: 1rem 1rem 0 0;width: 100%;padding:4rem 1rem 1rem 1rem;margin-top: -3rem;">
            <span style="font-weight: 300;text-align: center;">CAROLA CAPRA</span>
          <small style="text-align: center;">Enterpreneur, Ecopreneur, Changemaker</small>

          <small style="text-align: center;"><b>Bolivia, Plurinacional State.</b></small>
          </div>

          <img src="assets/imgs/biodiversity-cercoverde-carola.png" alt="" style="border-radius: 0 0 1rem 1rem;width: 100%;">
        </div>
      </div>
    </div>
  </section>

  <footer id="contacto" class="pt-4 pl-5 pr-5 border-top">
    <div class="row">
      <div class="col-sm-1">
      </div>
      <div class="col-sm-2 ml-0 mt-2 mb-t mr-2 text-center">
        <img alt="Cerco verde" src="assets/imgs/logo_white.png">
      </div>
      <div id="contacto_contacto" class="col-sm-3 m-2 left-border">
        <h5>
          {{'contacto.contacto' | translate}}
        </h5>
        <ul class="list-unstyled text-small" style="font-size: small;">
          <li>La Paz: Zona San Miguel, Calle Claudio Aliaga Nro. 1239.</li>
          <li>Santa Cruz: Calle Córdoba 7 Este N° 21 Equipetrol.</li>
          <li>&nbsp;</li>
          <li>Correo: lmujia@cercoverde.com</li>

          <li>Celular: +591 77795044</li>
          <!--          <li>Santa Cruz: Zona Equipetrol, Av. Noel Kempff Mercado Nro. 21, esquina Enrique Finot</li>-->
          <!--          <li>&nbsp;</li>-->
          <!--          <li>2-2771711 / 2-79192 / 3-3373722</li>-->
        </ul>
      </div>
      <div class="col-sm-3 m-2 text-center left-border">
        <h5>CercoVerde</h5>
        <ul class="list-unstyled text-small" style="font-size: small;">
          <li>
            <a class="link-secondary" href="https://www.facebook.com/Cerco-Verde-103542824371030" target="_blank">
              <img alt="Facebook" src="assets/imgs/facebook_icon.png" height="40">
              &nbsp;
            </a>
            <a class="link-secondary" href="https://twitter.com/CercoVerde" target="_blank">
              <img alt="Twitter" src="assets/imgs/twitter_icon.png" height="40">
              &nbsp;
            </a>
            <a class="link-secondary" href="https://www.instagram.com/bepartcercoverde" target="_blank">
              <img alt="Instragram" src="assets/imgs/instagram_icon.png" height="40">
              &nbsp;
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sm-2 m-2 text-center left-border">
        <ul class="list-unstyled text-small mt-0 mt-sm-5 d-none" style="font-size: x-small;">
          <li>&nbsp;</li>
          <li>
            <span style="cursor: pointer;" (click)="open(terminos_y_condiciones)">
              {{'contacto.terminos_y_condiciones' | translate}}
            </span>
          </li>
          <li>&nbsp;</li>
          <li>
            <span style="cursor: pointer;" (click)="open(preguntas_frecuentes)">
              {{'contacto.preguntas_frecuentes' | translate}}
            </span>
          </li>
        </ul>
      </div>
      <div class="col-sm-1 m-2">
      </div>
    </div>
    <div class="row text-center" style="font-size: x-small;">
      <div class="col-sm-12">
        <span style="font-weight: bold;">CercoVerde</span>
        {{'contacto.todos_los_derechos_reservados' | translate}}
        2022
        <br />
        &nbsp;
        <br />
        &nbsp;
      </div>
    </div>
  </footer>

  <ng-template #preserva_terrenos_verdes let-modal>
    <div class="modal-header">
      <h4 class="modal-title color-green">
        {{'modal.preserva_terrenos_verdes.title' | translate}}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      {{'modal.preserva_terrenos_verdes.body' | translate}}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">
        Cerrar
      </button>
    </div>
  </ng-template>

  <ng-template #preserva_comunidades_verdes let-modal>
    <div class="modal-header color-green">
      <h4 class="modal-title">
        {{'modal.preserva_comunidades_verdes.title' | translate}}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      {{'modal.preserva_comunidades_verdes.body' | translate}}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">
        Cerrar
      </button>
    </div>
  </ng-template>

  <ng-template #puntos_negocios_verdes let-modal>
    <div class="modal-header color-green">
      <h4 class="modal-title">
        {{'modal.puntos_negocios_verdes.title' | translate}}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      {{'modal.puntos_negocios_verdes.body' | translate}}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">
        Cerrar
      </button>
    </div>
  </ng-template>

  <ng-template #conviertete_en_guardian_verde let-modal>
    <div class="modal-header color-green">
      <h4 class="modal-title">
        {{'modal.conviertete_en_guardian_verde.title' | translate}}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      {{'modal.conviertete_en_guardian_verde.body' | translate}}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">
        Cerrar
      </button>
    </div>
  </ng-template>

  <ng-template #terminos_y_condiciones let-modal>
    <div class="modal-header color-green">
      <h4 class="modal-title">
        {{'modal.terminos_y_condiciones.title' | translate}}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nec aliquam mi. Integer sit amet blandit nibh.
        Suspendisse sollicitudin erat nec risus ultrices semper. In hac habitasse platea dictumst. Etiam aliquet ex a
        elit interdum sodales. Fusce vestibulum est quis quam finibus, et porttitor risus consectetur. Nam et molestie
        lorem.
      </p>
      <p>
        Vestibulum pretium suscipit blandit. Sed laoreet libero eu felis auctor pellentesque. Nullam a mi at risus
        tempor sollicitudin a a elit. Nulla sollicitudin, leo in rhoncus dictum, metus libero eleifend est, at finibus
        lacus lacus et mauris. Ut in justo tempor, lobortis sem et, viverra massa. Aenean at lorem ac est consequat
        fermentum. Vestibulum tempus tincidunt maximus.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">
        Cerrar
      </button>
    </div>
  </ng-template>

  <ng-template #preguntas_frecuentes let-modal>
    <div class="modal-header color-green">
      <h4 class="modal-title">
        {{'modal.preguntas_frecuentes.title' | translate}}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <dl>
        <dt>Aliquam id magna non diam ultricies condimentum non eu eros?</dt>
        <dd>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non
          malesuada nunc.</dd>
        <dt>Nunc ac justo et nisl volutpat dictum vel a lectus?</dt>
        <dd>Vivamus at leo elit. Suspendisse id felis vel sapien fringilla laoreet nec eu nunc. Pellentesque ac justo
          porttitor, mollis metus eu, congue nibh. Nunc nec volutpat purus, vitae consequat felis.</dd>
      </dl>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">
        Cerrar
      </button>
    </div>
  </ng-template>

</main>