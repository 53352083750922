import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'CercoVerde';

  closeResult: string;

  constructor(
    private modalService: NgbModal,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'es']);

    if (window.navigator.language.substr(0, 2) === 'en') {
      translate.setDefaultLang('en');
    } else {
      translate.setDefaultLang('es');
    }
  }

  switchLang(lang: string): void {
    this.translate.use(lang);
  }

  open(content): void {
    this.modalService.open(content, {}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed with reason: ${reason}`;
    });

    window.open("https://wa.me/59177795044?text=Hola,%20me%20interesa%20ser%20parte%20de%20Cerco%20Verde")
  }

}
